// `react-markdown` depends on `Object.hasOwn()` but it is not supported
// everywhere yet, most notably in iOS 14
import "@ungap/has-own";

import React from "react";
import { SpeedInsights } from "@vercel/speed-insights/next";
import { AppProps } from "next/app";
import { appWithTranslation } from "next-i18next";
import NextI18nextConfig from "../../next-i18next.config";
import localFont from "next/font/local";
import { isOrg } from "../utils/site";
import classNames from "classnames";

import "../styles/global.css";
import "../styles/icons.css";
import { ErrorComponentFullPage } from "components/PageTemplates/ErrorPage";
import { ErrorBoundary } from "@sentry/nextjs";
import { useWebTranslation } from "translations";

// FIXME - temporary solution, want to get rid of page components going forward
const commonPagesPaths = [
  "/payments",
  "/signup",
  "/api",
  "[[...slug]]",
  "/404",
  "/",
];

const eina = localFont({
  src: "../../public/fonts/Eina01-Regular.woff2",
  weight: "400",
  style: "normal",
  display: "swap",
  variable: "--font-eina",
});

const einaSemiBold = localFont({
  src: "../../public/fonts/Eina01-SemiBold.woff2",
  weight: "400",
  style: "normal",
  display: "swap",
  variable: "--font-eina-semi-bold",
});

const einaBold = localFont({
  src: "../../public/fonts/Eina01-Bold.woff2",
  weight: "400",
  style: "normal",
  display: "swap",
  variable: "--font-eina-bold",
});

function App({ Component, pageProps, router }: AppProps) {
  const { t } = useWebTranslation();

  // Start of (hopefully) temporary hack for not showing swedish pages on german site
  if (isOrg("ger")) {
    const isContentfulPage = router.route === "/[[...slug]]";
    const isCommonPage = commonPagesPaths.some((path) =>
      router.asPath.startsWith(path)
    );
    if (!isContentfulPage && !isCommonPage) {
      router.replace("/404");
    }
  }
  // End

  console.log(
    process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY,
    process.env.NEXT_PUBLIC_AMPLITUDE_EXPERIMENT_DEPLOYMENT_KEY
  );

  return (
    <div
      className={classNames(
        "app",
        eina.variable,
        einaSemiBold.variable,
        einaBold.variable
      )}
    >
      <SpeedInsights />
      <ErrorBoundary
        fallback={
          <ErrorComponentFullPage
            title={t("500.title")}
            description={t("500.description")}
            imgSrc="/images/error-500.svg"
          />
        }
      >
        {/* { Inject the font families globally so portals can inherit them} */}
        <style global jsx>
          {`
            :root {
              --font-eina: ${eina.style.fontFamily};
              --font-eina-bold: ${einaSemiBold.style.fontFamily};
              --font-eina-semi-bold: ${einaSemiBold.style.fontFamily};
            }
          `}
        </style>
        <Component {...pageProps} />
      </ErrorBoundary>
    </div>
  );
}

export default appWithTranslation(App, NextI18nextConfig);
