import {
  addDays,
  isAfter,
  isBefore,
  isSameDay,
  isValid,
  parse,
  startOfDay,
} from "date-fns";
import Personnummer from "personnummer";

import { getWebTranslation } from "../translations";
import { isNumeric } from "./isNumeric";
import { DateFormat } from "config/site";
import { getDateFormat } from "./site";
import { assertNever } from "./typescript";

type LooseString = string | null | undefined;

export const notEmpty = (...strings: LooseString[]): boolean => {
  return strings.some((string) => {
    return !(string?.length ? string.length > 0 : false);
  });
};

export const validateEmail = (string: LooseString): boolean => {
  const validEmailRegexp = new RegExp(
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );

  return validEmailRegexp.test(string ?? "");
};

export const validatePhone = (string: LooseString): boolean => {
  return (string?.length ?? 0) > 7;
};

export const validateSsn = (string: string): boolean => {
  return Personnummer.valid(string);
};

export const ssnFormatter = (string: string | undefined): string => {
  if (!string) {
    return "";
  }
  const digits = (string.match(/\d/g) || []).join("");
  const isLongFormat = string.startsWith("19") || string.startsWith("20");
  if (string.length > 6 && !isLongFormat) {
    return [digits.slice(0, 6), "-", digits.slice(6)].join("");
  }
  if (string.length > 8 && isLongFormat) {
    return [digits.slice(0, 8), "-", digits.slice(8)].join("");
  }
  return digits;
};

export type DateFormats = DateFormat | "dd-MM-yyyy"; //dd-MM-yyyy is deprecated

export const dateValid = (
  date: string,
  format: DateFormats = "yyyy-MM-dd"
): boolean => {
  if (format === "dd-MM-yyyy") {
    throw new Error("Old date format used for validation");
  }

  return date?.length === 10 && isValid(parse(date, format, new Date()));
};

export const dateValidMin = (date: Date, min: Date): boolean => {
  return isSameDay(date, min) || isAfter(date, min);
};

export const dateValidMax = (date: Date, max: Date): boolean => {
  return isSameDay(date, max) || isBefore(date, max);
};

export const dateInRange = (
  date: Date,
  offsetInDaysStart: number,
  offsetInDaysEnd: number
) => {
  return (
    dateValidMin(date, startOfDay(addDays(new Date(), offsetInDaysStart))) &&
    dateValidMax(date, addDays(new Date(), offsetInDaysEnd))
  );
};

function splitDate(date: string, format: DateFormat) {
  switch (format) {
    case "dd.MM.yyyy": {
      const [day = "", month = "", year = ""] = date.split(".");

      return [year, month, day];
    }

    case "yyyy-MM-dd": {
      const [year = "", month = "", day = ""] = date.split("-");

      return [year, month, day];
    }

    case "dd/MM/yyyy": {
      const [day = "", month = "", year = ""] = date.split("/");

      return [year, month, day];
    }

    default:
      assertNever(format);
  }
}

type PartialDate = {
  year?: string;
  month?: string;
  day?: string;
};

function formatDate({ year, month, day }: PartialDate, format: DateFormat) {
  switch (format) {
    case "dd.MM.yyyy": {
      if (day && !month && !year) {
        return day.length === 2 ? `${day}.` : day;
      }

      if (day && month && !year) {
        return month.length === 2 ? `${day}.${month}.` : `${day}.${month}`;
      }

      if (day && month && year) {
        return `${day}.${month}.${year}`;
      }

      return "";
    }
    case "yyyy-MM-dd": {
      if (year && !month && !day) {
        return year.length === 4 ? `${year}-` : year;
      }

      if (year && month && !day) {
        return month.length === 2 ? `${year}-${month}-` : `${year}-${month}`;
      }

      if (year && month && day) {
        return `${year}-${month}-${day}`;
      }

      return "";
    }

    case "dd/MM/yyyy": {
      if (day && !month && !year) {
        return day.length === 2 ? `${day}/` : day;
      }

      if (day && month && !year) {
        return month.length === 2 ? `${day}/${month}/` : `${day}/${month}`;
      }

      if (day && month && year) {
        return `${day}/${month}/${year}`;
      }

      return "";
    }

    default:
      assertNever(format);
  }
}

export const dateFormatter = ({
  date,
  prevDate,
  format,
}: {
  date: string;
  prevDate: string;
  format: DateFormats;
}): string => {
  if (format === "dd-MM-yyyy") {
    throw new Error("Old date format used for formatting");
  }

  const isBackspace = (prevDate?.length ?? 0) > date.length;

  if (isBackspace) {
    return date;
  }

  if (date.match(/[^\d-\.\/]/g)) {
    return prevDate;
  }

  let [year, month, day] = splitDate(date, format);

  if (year.length > 4) {
    if (!month) {
      month = year.slice(4);
    }

    year = year.slice(0, 4);
  }

  if (month.length > 2) {
    if (!day) {
      day = month.slice(2);
    }

    month = month.slice(0, 2);
  }

  if (month.length === 1 && parseInt(month, 10) > 1) {
    month = `0${month}`;
  }

  if (parseInt(month, 10) > 12) {
    month = "12";
  }

  if (day.length === 1 && parseInt(day, 10) > 3) {
    day = `0${day}`;
  }

  if (parseInt(day, 10) > 31) {
    day = "31";
  }

  return formatDate({ year, month, day }, format);
};

export const formatErrorMessage = (error: any | null): string => {
  if (!error) {
    return "";
  }

  if (error.message === "No person found") {
    return getWebTranslation("validation.no_person_found");
  }

  if (error.message === "effectiveDate can not be in the past") {
    return getWebTranslation("validation.past");
  }

  if (
    error.message === "effectiveDate can not be over one year in the future"
  ) {
    return getWebTranslation("validation.future");
  }

  if (error.message === "Ssn must be a valid swedish personal number") {
    return getWebTranslation("validation.ssn_invalid_format");
  }

  if (error.message === "No person found") {
    return getWebTranslation("validation.ssn_not_found");
  }

  if (error.message === "No person available") {
    return getWebTranslation("validation.ssn_not_available");
  }

  if (error.message === "Person did not have a valid municipality code") {
    return getWebTranslation("validation.ssn_incomplete_response");
  }

  if (error.message?.startsWith("Client must be of age 18 or more")) {
    return getWebTranslation("validation.underage_person");
  }

  if (error.message.startsWith("Could not find region code for zip code")) {
    return getWebTranslation("validation.zip");
  }

  const graphQLErrorName =
    error.graphQLErrors?.[0]?.extensions?.exception?.name;

  if (graphQLErrorName === "INVALID_ZIP") {
    return getWebTranslation("validation.zip");
  }

  if (error?.name === "ZIP") {
    return getWebTranslation("validation.zip");
  }

  const graphQLMessage =
    error.graphQLErrors?.[0]?.extensions?.exception?.data?.message;

  if (
    graphQLMessage === "Dog is too young to be insured" ||
    graphQLMessage === "Cog is too young to be insured"
  ) {
    return getWebTranslation("validation.young");
  }

  if (graphQLMessage && graphQLMessage.startsWith("Unable to insure")) {
    return getWebTranslation("validation.unable");
  }

  if (
    graphQLMessage &&
    graphQLMessage.startsWith("Unable to determine risk group")
  ) {
    return getWebTranslation("validation.risk_group");
  }

  if (
    graphQLMessage &&
    graphQLMessage.startsWith("Unable to offer life coverage")
  ) {
    return getWebTranslation("validation.life_coverage");
  }

  return getWebTranslation("validation.unknown");
};

const atLeastOneWordRegex = /^[a-zA-Z\s\/\-)(`."']+$/;

export const streetValid = (str: string) => atLeastOneWordRegex.test(str);
export const houseNumberValid = (str: string) => str.length && isNumeric(str);
export const germanZipValid = (str: string) =>
  /^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/.test(str);
export const cityValid = (str: string) => atLeastOneWordRegex.test(str);

/**
 * Test if a string is a valid french post code (zip)
 *
 * @see https://rgxdb.com/r/354H8M0X
 * @param str string to test
 * @returns
 */
export const frenchZipValid = (str: string) =>
  /^(?:[0-8]\d|9[0-8])\d{3}$/.test(str);
